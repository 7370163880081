import React from 'react';

const Input = ({ placeholder, onChange, error, value, className, title, ...props }) => {
  return (
    <div>
      {title && <label className="block mb-2 text-sm font-medium text-gray-900">{title}</label>}
      <input
        value={value}
        className={`appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 rounded text-gray-900 focus:outline-none focus:ring-primary-500 focus:border-blue-500 focus:z-10 sm:text-sm ${className}`}
        placeholder={placeholder}
        onChange={(e) => onChange(e.target.value)}
        {...props}
      />
      {error && <div className={`text-xs text-red-400 pt-2 ${!error && 'pb-4'}`}>{error}</div>}
    </div>
  );
};

export default Input;
