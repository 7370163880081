import React, { useEffect, useState } from 'react';
import DefaultButton from '@core/buttons/electrons/DefaultButton';
import RefundTicketModal from '@components/Ticket/RefundTicketModal';
import TicketService from '@services/TicketService';
import { useDispatch } from 'react-redux';
import { hideSpinner, showSpinner } from '@redux/spinner/Action';
import { useHistory } from 'react-router-dom';
import _ from 'lodash';

const TicketInfo = ({ match }) => {
  const [viewRefundTicketModal, setViewTicketModalRefund] = useState(false);
  const [ticket, setTicket] = useState({});
  const [linkedTickets, setLinkedTickets] = useState([]);
  const seatId = _.get(match, 'params.id', '');
  const dispatch = useDispatch();
  const history = useHistory();
  const [show, setShow] = useState({});
  const [seat, setSeat] = useState({});
  const [client, setClient] = useState({});
  const [discount, setDiscount] = useState({});

  useEffect(() => {
    dispatch(showSpinner());
    TicketService.get(seatId)
      .then((res) => {
        const ticket = _.get(res, 'data.data.ticket');
        const linkedTickets = _.get(res, 'data.data.linkedTickets');
        const { client, seat, show, discount } = ticket;
        setShow(show);
        setSeat(seat);
        setClient(client);
        setTicket(ticket);
        setDiscount(discount);
        setLinkedTickets(linkedTickets);
      })
      .catch(() => {
        history.goBack();
      })
      .finally(() => {
        dispatch(hideSpinner());
      });
  }, []);

  const showRefundTicketModal = () => setViewTicketModalRefund(true);

  return (
    <div className="border bg-white rounded justify-between items-center mb-5 py-2">
      <div className="mx-3 my-5">
        <label className="text-lg font-bold text-gray-800">Ticket info</label>
        <DefaultButton label={'Refund'} xs onClick={showRefundTicketModal} />
        <table className="mx-auto w-5/6 text-sm text-left text-gray-500">
          <tbody>
            <tr className="bg-white">
              <th className="py-4 px-6 font-medium text-gray-900 whitespace-nowrap">Client Name</th>
              <td className="py-4 px-6">{`${client.name} ${client.surname}`}</td>
            </tr>
            <tr className="bg-white">
              <th className="py-4 px-6 font-medium text-gray-900 whitespace-nowrap">
                Client email
              </th>
              <td className="py-4 px-6">{client.email}</td>
            </tr>
            <tr className="bg-white">
              <th className="py-4 px-6 font-medium text-gray-900 whitespace-nowrap">
                Client phone
              </th>
              <td className="py-4 px-6">{client.phone}</td>
            </tr>
            <tr className="bg-white">
              <th className="py-4 px-6 font-medium text-gray-900 whitespace-nowrap">Show Name</th>
              <td className="py-4 px-6">{show.name}</td>
            </tr>
            <tr className="bg-white">
              <th className="py-4 px-6 font-medium text-gray-900 whitespace-nowrap">Seat Name</th>
              <td className="py-4 px-6">{seat.row + seat.name}</td>
            </tr>
            <tr className="bg-white ">
              <th className="py-4 px-6 font-medium text-gray-900 whitespace-nowrap">Floor</th>
              <td className="py-4 px-6">{seat.floor}</td>
            </tr>
            <tr className="bg-white ">
              <th className="py-4 px-6 font-medium text-gray-900 whitespace-nowrap">Discount</th>
              <td className="py-4 px-6">
                {discount ? `${discount.name} ${discount.percentage}%` : 'No discount'}
              </td>
            </tr>
            <tr className="bg-white ">
              <th className="py-4 px-6 font-medium text-gray-900 whitespace-nowrap">Price</th>
              <td className="py-4 px-6">{ticket.price} ALL</td>
            </tr>
          </tbody>
        </table>
      </div>
      {viewRefundTicketModal && (
        <RefundTicketModal
          ticketId={ticket.id}
          setOpenModal={setViewTicketModalRefund}
          linkedTickets={linkedTickets}
        />
      )}
    </div>
  );
};

export default TicketInfo;
