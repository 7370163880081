import React, { useState } from 'react';
import BlankModal from '@core/modals/BlankModal';
import Input from '@core/inputs/Input';
import DefaultButton from '@core/buttons/electrons/DefaultButton';
import DiscountsService from '@services/DiscountsService';
import { useDispatch } from 'react-redux';
import { hideSpinner, showSpinner } from '@redux/spinner/Action';
import { showError, showSuccess } from '@utils/helpers';
import useFormErrors from '@hooks/useFormErrors';
import addDiscountValidator from '@utils/validators/addDiscountValidator';

const AddDiscountModal = ({ setOpenModal, setReloaded }) => {
  const [name, setName] = useState('');
  const [percentage, setPercentage] = useState('');
  const dispatch = useDispatch();
  const { clearErrors, getError, validateErrors } = useFormErrors();

  const onSubmit = () => {
    const errors = validateErrors({ name, percentage }, addDiscountValidator);
    if (errors) {
      return;
    }
    dispatch(showSpinner());
    DiscountsService.create(name, percentage)
      .then((res) => {
        showSuccess(res.data.message);
        setReloaded((prevState) => prevState + 1);
        setOpenModal(false);
      })
      .catch((err) => {
        showError(err.response.data.message);
      })
      .finally(() => {
        dispatch(hideSpinner());
      });
  };

  const AddButton = <DefaultButton onClick={onSubmit} label="Add Discount" />;

  return (
    <BlankModal title="Add Discount" setOpenModal={setOpenModal} otherButtons={[AddButton]}>
      <div className="space-y-3">
        <Input
          onChange={setName}
          value={name}
          placeholder="Name"
          error={getError('name')}
          onFocus={clearErrors}
        />
        <Input
          type="number"
          onChange={setPercentage}
          value={percentage}
          placeholder="Percentage"
          error={getError('percentage')}
          onFocus={clearErrors}
        />
      </div>
    </BlankModal>
  );
};

export default AddDiscountModal;
