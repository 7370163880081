import API from '@utils/plugins/API';

const ShowService = {
  create: (body) => API.post('/internal/show', body),
  all: (params) => API.get('/internal/show', { params }),
  edit: (showId, body) => API.patch(`/internal/show/${showId}`, body),
  delete: (showId) => API.delete(`/internal/show/${showId}`)
};

export default ShowService;
