import React from 'react';
import _ from 'lodash';
import { hideSpinner, showSpinner } from '@redux/spinner/Action';
import TicketService from '@services/TicketService';
import { showError, showSuccess } from '@utils/helpers';
import { useDispatch } from 'react-redux';
import QrReader from 'react-web-qr-reader';

const Scanner = ({ match }) => {
  const dispatch = useDispatch();
  const onScan = (result) => {
    const ticketId = _.get(result, 'data', null);
    const showId = _.get(match, 'params.showId', null);
    if (ticketId) {
      dispatch(showSpinner());
      TicketService.checkUsage(ticketId, showId)
        .then((res) => {
          showSuccess(res.data.message);
        })
        .catch((err) => {
          showError(err.response.data.message);
        })
        .finally(() => {
          dispatch(hideSpinner());
        });
    }
  };

  return (
    <QrReader
      onError={(error) => showError(error.message)}
      facingMode="environment"
      onScan={onScan}
      showViewFinder={false}
      delay={500}
    />
  );
};

export default Scanner;
