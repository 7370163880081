import React from 'react';

const FileInput = ({ value, onChange, error, ...rest }) => {
  return (
    <div>
      <input
        value={value}
        type="file"
        onChange={(e) => onChange(e.target.files)}
        className="relative file:py-1.5 block w-full flex-auto rounded border border-solid border-neutral-300 bg-clip-padding px-2 py-2 text-base font-normal text-neutral-700 transition duration-300 ease-in-out file:overflow-hidden file:rounded-none file:border-0 file:border-solid file:border-inherit file:bg-neutral-100 file:text-neutral-700 file:transition file:duration-150 file:ease-in-out file:[margin-inline-end:0.75rem] file:[border-inline-end-width:1px] hover:file:bg-neutral-200 focus:border-primary focus:text-neutral-700 focus:shadow-[0_0_0_1px] focus:shadow-primary focus:outline-none"
        {...rest}
      />
      {error && <div className={`text-xs text-red-400 pt-2 ${!error && 'pb-4'}`}>{error}</div>}
    </div>
  );
};

export default FileInput;
