import React, { useMemo, useState } from 'react';
import Moment from 'react-moment';
import Datatable from '@core/table/Datatable';
import ShowService from '@services/ShowService';
import DefaultButton from '@core/buttons/electrons/DefaultButton';
import { FaQrcode, FaPen, FaEye } from 'react-icons/fa';
import { MdOutlineEventSeat } from 'react-icons/md';
import EditShowModal from '@components/Show/EditShowModal';
import AddShowModal from '@components/Show/AddShowModal';
import AddButton from '@core/buttons/atoms/AddButton';
import _ from 'lodash';
import DeleteButton from '@core/buttons/atoms/DeleteButton';
import ConfirmDeleteModal from '@core/modals/ConfirmDeleteModal';
import ShowModal from '@components/Show/ShowModal';

const ShowTable = () => {
  const [shows, setShows] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [reloaded, setReloaded] = useState(0);
  const [viewEditShowModal, setViewEditShowModal] = useState(false);
  const [viewAddShowModal, setViewAddShowModal] = useState(false);
  const [viewShowModal, setViewShowModal] = useState(false);
  const [show, setShow] = useState(null);
  const [showId, setShowId] = useState(null);
  const [viewConfirmDeleteModal, setViewConfirmDeleteModal] = useState(false);
  const [totalRows, setTotalRows] = useState(0);

  const editAction = (show) => {
    setViewEditShowModal(true);
    setShow(show);
  };

  const openViewConfirmDeleteModal = (showId) => {
    setViewConfirmDeleteModal(true);
    setShowId(showId);
  };

  const handleViewButtonClick = (row) => {
    setShow(row);
    setViewShowModal(true);
  };

  const ActionsColumn = (row) => (
    <div className={`flex items-center flex-wrap`}>
      <DefaultButton
        label={<FaEye />}
        bgColor="bg-blue-500"
        bgColorHover="hover:bg-blue-600"
        onClick={() => handleViewButtonClick(row)}
      />
      <DefaultButton
        label={<FaQrcode />}
        bgColor="bg-gray-500"
        bgColorHover="hover:bg-gray-600"
        link
        to={`/ticket-scan/${row.id}`}
      />
      <DefaultButton
        bgColor="bg-primary-600"
        bgColorHover="hover:bg-primary-700"
        label={<MdOutlineEventSeat />}
        link
        to={`/seats/${row.id}`}
      />
      <DefaultButton
        label={<FaPen />}
        bgColor="bg-yellow-400"
        bgColorHover="hover:bg-yellow-500"
        onClick={() => editAction(row)}
      />
      <DeleteButton onClick={() => openViewConfirmDeleteModal(row.id)} />
    </div>
  );

  const headers = useMemo(
    () => [
      { name: 'Name', cell: (row) => row.name },
      { name: 'Base Price', cell: (row) => row.basePrice },
      { name: 'Date', selector: (row) => <Moment format="DD.MM.YYYY hh:mm A">{row.date}</Moment> },
      { name: 'Actions', cell: ActionsColumn }
    ],
    []
  );
  const getData = (params) => {
    ShowService.all(params)
      .then((res) => {
        const data = _.get(res, 'data.data.data.shows', []);
        const totalRows = _.get(res, 'data.data.count.totalRows', data.length);
        setShows(data);
        setTotalRows(totalRows);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <div className="border bg-white rounded justify-between items-center mb-5 py-2">
      <div className="mx-3 my-5">
        <label className="text-lg font-bold text-gray-800">Show List</label>
        <Datatable
          totalRows={totalRows}
          title={<AddButton label="Add Show" onClick={() => setViewAddShowModal(true)} />}
          headers={headers}
          data={shows}
          getData={getData}
          isLoading={isLoading}
          extraDependencies={[reloaded]}
        />
      </div>
      {viewEditShowModal && (
        <EditShowModal
          setReloaded={setReloaded}
          setOpenModal={setViewEditShowModal}
          showToEdit={show}
          setShowToEdit={setShow}
        />
      )}
      {viewAddShowModal && (
        <AddShowModal setReloaded={setReloaded} setOpenModal={setViewAddShowModal} />
      )}
      {viewConfirmDeleteModal && (
        <ConfirmDeleteModal
          setOpenModal={setViewConfirmDeleteModal}
          setReloaded={setReloaded}
          recordId={showId}
          setRecordId={setShowId}
          service={ShowService}
        />
      )}
      {viewShowModal && <ShowModal setOpenModal={setViewShowModal} show={show} />}
    </div>
  );
};

export default ShowTable;
