import React, { useState } from 'react';
import BlankModal from '@core/modals/BlankModal';
import Input from '@core/inputs/Input';
import { showError, showSuccess } from '@utils/helpers';
import { hideSpinner, showSpinner } from '@redux/spinner/Action';
import { useDispatch } from 'react-redux';
import DefaultButton from '@core/buttons/electrons/DefaultButton';
import useFormErrors from '@hooks/useFormErrors';
import confirmDeleteValidator from '@utils/validators/confirmDeleteValidator';

const ConfirmDeleteModal = ({ service, setOpenModal, setReloaded, recordId, setRecordId }) => {
  const [confirmDeleteText, setConfirmDeleteText] = useState('');
  const dispatch = useDispatch();

  const { validateErrors, clearErrors, getError } = useFormErrors();
  const confirmDelete = () => {
    const errors = validateErrors(
      { confirmDeleteText, deleteText: 'DELETE' },
      confirmDeleteValidator
    );
    if (errors) {
      return;
    }
    dispatch(showSpinner());
    service
      .delete(recordId)
      .then((res) => {
        setReloaded((prevState) => prevState + 1);
        showSuccess(res.data.message);
        setRecordId(null);
        setOpenModal(false);
      })
      .catch((err) => {
        showError(err.response.data.message);
      })
      .finally(() => {
        dispatch(hideSpinner());
      });
  };

  const DeleteButton = <DefaultButton label="Delete" onClick={confirmDelete} />;

  return (
    <BlankModal title="Confirm Delete" setOpenModal={setOpenModal} otherButtons={[DeleteButton]}>
      <p>Are you sure you want to delete this record?</p>
      <p className="mb-2">Enter the following to confirm:</p>
      <code className="bg-gray-300 p-1.5 rounded-lg">DELETE</code>
      <Input
        className="mt-3"
        placeholder="Enter confirmation text"
        value={confirmDeleteText}
        error={getError('confirmDeleteText')}
        onChange={setConfirmDeleteText}
        onFocus={clearErrors}
      />
    </BlankModal>
  );
};

export default ConfirmDeleteModal;
