const loginValidator = {
  email: {
    presence: {
      allowEmpty: false,
      message: "Email can't be blank"
    },
    type: 'string'
  },
  password: {
    presence: {
      allowEmpty: false,
      message: "Password can't be blank"
    },
    type: 'string'
  }
};

export default loginValidator;
