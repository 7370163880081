import React, { useState } from 'react';
import { showError } from '@utils/helpers';
import { useDispatch } from 'react-redux';
import { authenticate } from '@redux/authentication/Action';
import { useHistory } from 'react-router-dom';
import { hideSpinner, showSpinner } from '@redux/spinner/Action';
import AuthService from '@services/AuthService';
import Input from '@core/inputs/Input';
import _ from 'lodash';
import DefaultButton from '@core/buttons/electrons/DefaultButton';
import useFormErrors from '@hooks/useFormErrors';
import loginValidator from '@utils/validators/loginValidator';
import logo from '@assets/images/tob-logo.png';

const LoginForm = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const { validateErrors, clearErrors, getError } = useFormErrors();

  const onSubmit = (e) => {
    e.preventDefault();
    const errors = validateErrors({ email, password }, loginValidator);
    if (errors) {
      return;
    }
    dispatch(showSpinner('Please wait...'));
    AuthService.login(email, password)
      .then((response) => {
        const authentication = _.get(response, 'data.data.tokenData', '');
        const user = _.get(response, 'data.data.user', '');
        dispatch(authenticate(authentication, user));
        history.push('/');
      })
      .catch((err) => {
        showError(err.response.data.message);
      })
      .finally(() => {
        dispatch(hideSpinner());
      });
  };

  return (
    <>
      <div>
        <img src={logo} className="w-30 block mx-auto" />
        <h2 className="mt-5 text-center text-3xl font-extrabold text-gray-900">
          Sign in to your account
        </h2>
      </div>
      <form className="mt-8 space-y-6" onSubmit={onSubmit}>
        <div className="rounded-md shadow-sm space-y-3">
          <Input
            value={email}
            error={getError('email')}
            placeholder={'Email address'}
            onChange={setEmail}
            onFocus={clearErrors}
          />
          <Input
            error={getError('password')}
            type={'password'}
            value={password}
            placeholder={'Password'}
            onChange={setPassword}
            onFocus={clearErrors}
          />
        </div>
        <DefaultButton type="submit" label="Sign in" fullWidth />
      </form>
    </>
  );
};

export default LoginForm;
