import React from 'react';
import Seatmap from '@components/Seats/Seatmap';
import Layout from '@hoc/layouts/Layout';

const SeatsPage = (props) => {
  return (
    <Layout>
      <Seatmap {...props} />
    </Layout>
  );
};

export default SeatsPage;
