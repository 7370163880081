import React, { useMemo, useState } from 'react';
import Datatable from '@core/table/Datatable';
import ClientsService from '@services/ClientsService';
import _ from 'lodash';

const ClientsTable = () => {
  const [clients, setClients] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [totalRows, setTotalRows] = useState(0);
  const headers = useMemo(
    () => [
      { name: 'First Name', cell: (row) => row.name },
      { name: 'Last Name', cell: (row) => row.surname },
      { name: 'Email', cell: (row) => row.email },
      { name: 'Phone Number', cell: (row) => row.phone }
    ],
    []
  );

  const getData = (params) => {
    ClientsService.all(params)
      .then((res) => {
        const data = _.get(res, 'data.data.data.clients', []);
        const totalRows = _.get(res, 'data.data.count.totalRows', data.length);
        setClients(data);
        setTotalRows(totalRows);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <div className="border bg-white rounded justify-between items-center mb-5 py-2">
      <div className="mx-3 my-5">
        <label className="text-lg font-bold text-gray-800">Clients List</label>
        <Datatable
          totalRows={totalRows}
          getData={getData}
          isLoading={isLoading}
          headers={headers}
          data={clients}
        />
      </div>
    </div>
  );
};

export default ClientsTable;
