import React from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

const RichTextEditor = ({ data = '', onChange, onFocus, error, disabled, title }) => {
  return (
    <div>
      {title && <label className="block mb-2 text-sm font-medium text-gray-900">{title}</label>}
      <CKEditor
        editor={ClassicEditor}
        data={data}
        disabled={disabled}
        onChange={(event, editor) => onChange(editor.getData())}
        onFocus={onFocus}
      />
      {error && <div className={`text-xs text-red-400 pt-2 ${error && 'pb-4'}`}>{error}</div>}
    </div>
  );
};

export default RichTextEditor;
