import React, { useState } from 'react';
import Input from '@core/inputs/Input';
import DefaultButton from '@core/buttons/electrons/DefaultButton';
import useFormErrors from '@hooks/useFormErrors';
import AuthService from '@services/AuthService';
import { useDispatch, useSelector } from 'react-redux';
import { hideSpinner, showSpinner } from '@redux/spinner/Action';
import { showError, showSuccess } from '@utils/helpers';
import { useHistory } from 'react-router-dom';
import changePasswordValidator from '@utils/validators/changePasswordValidator';
import _ from 'lodash';
import logo from '@assets/images/tob-logo.png';

const ChangePasswordForm = () => {
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const dispatch = useDispatch();
  const history = useHistory();
  const { clearErrors, getError, validateErrors } = useFormErrors();
  const userId = useSelector((state) => _.get(state, 'meReducer.id', ''));

  const changePassword = () => {
    const errors = validateErrors(
      { oldPassword, newPassword, confirmPassword },
      changePasswordValidator
    );
    if (errors) {
      return;
    }
    dispatch(showSpinner());
    AuthService.changePassword(newPassword, oldPassword, userId)
      .then((res) => {
        showSuccess(res.data.message);
        history.push('/');
      })
      .catch((error) => {
        showError(error.response.data.message);
      })
      .finally(() => {
        dispatch(hideSpinner());
      });
  };

  return (
    <>
      <div>
        <img src={logo} className="w-30 block mx-auto" />
        <h2 className="mt-5 text-center text-3xl font-extrabold text-gray-900">Change Password</h2>
      </div>
      <div className="space-y-3">
        <Input
          type={'password'}
          placeholder={'Old password'}
          value={oldPassword}
          onChange={setOldPassword}
          onFocus={clearErrors}
          error={getError('oldPassword')}
        />
        <Input
          type={'password'}
          placeholder={'New password'}
          value={newPassword}
          onChange={setNewPassword}
          onFocus={clearErrors}
          error={getError('newPassword')}
        />
        <Input
          type={'password'}
          error={getError('confirmPassword')}
          placeholder={'Confirm new password'}
          value={confirmPassword}
          onChange={setConfirmPassword}
          onFocus={clearErrors}
        />
      </div>
      <DefaultButton fullWidth label="Change Password" onClick={changePassword} />
    </>
  );
};
export default ChangePasswordForm;
