import React from 'react';
import Row from '@components/Seats/Row';

const Floor = ({ floor, selectedSeats, setSelectedSeats }) => {
  const { x, y, seats } = floor;
  const rows = Array.from(Array(y).keys());
  const columns = Array.from(Array(x).keys());
  return (
    <div className="flex flex-col sm:space-y-1 xl:space-y-1.5 items-center pb-3">
      {rows.map((y, index) => (
        <Row
          key={index}
          columns={columns}
          y={y}
          seats={seats}
          selectedSeats={selectedSeats}
          setSelectedSeats={setSelectedSeats}
        />
      ))}
    </div>
  );
};

export default Floor;
