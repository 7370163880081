import React, { useMemo, useState } from 'react';
import Datatable from '@core/table/Datatable';
import AddButton from '@core/buttons/atoms/AddButton';
import AddDiscountModal from '@components/Discounts/AddDiscountModal';
import DiscountsService from '@services/DiscountsService';
import DeleteButton from '@core/buttons/atoms/DeleteButton';
import ConfirmDeleteModal from '@core/modals/ConfirmDeleteModal';
import _ from 'lodash';

const DiscountsTable = () => {
  const [viewAddDiscountsModal, setViewAddDiscountsModal] = useState(false);
  const [viewConfirmDeleteModal, setViewConfirmDeleteModal] = useState(false);
  const [reloaded, setReloaded] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [discounts, setDiscounts] = useState([]);
  const [discountId, setDiscountId] = useState(null);
  const [totalRows, setTotalRows] = useState(0);
  const openAddDiscountModal = () => setViewAddDiscountsModal(true);
  const openViewConfirmDeleteModal = (discountId) => {
    setViewConfirmDeleteModal(true);
    setDiscountId(discountId);
  };
  const headers = useMemo(
    () => [
      {
        name: 'Name',
        cell: (row) => row.name
      },
      {
        name: 'Percentage',
        cell: (row) => row.percentage
      },
      {
        name: 'Actions',
        selector: (row) => <DeleteButton onClick={() => openViewConfirmDeleteModal(row.id)} />
      }
    ],
    []
  );

  const getData = (params) => {
    DiscountsService.all(params)
      .then((res) => {
        const data = _.get(res, 'data.data.data.discounts', []);
        const totalRows = _.get(res, 'data.data.count.totalRows', data.length);
        setDiscounts(data);
        setTotalRows(totalRows);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <div className="border bg-white rounded justify-between items-center mb-5 py-2">
      <div className="mx-3 my-5">
        <label className="text-lg font-bold text-gray-800">Discounts List</label>
        <Datatable
          totalRows={totalRows}
          data={discounts}
          headers={headers}
          isLoading={isLoading}
          title={<AddButton label="Add Discount" onClick={openAddDiscountModal} />}
          extraDependencies={[reloaded]}
          getData={getData}
        />
      </div>
      {viewAddDiscountsModal && (
        <AddDiscountModal setReloaded={setReloaded} setOpenModal={setViewAddDiscountsModal} />
      )}
      {viewConfirmDeleteModal && (
        <ConfirmDeleteModal
          service={DiscountsService}
          recordId={discountId}
          setRecordId={setDiscountId}
          setOpenModal={setViewConfirmDeleteModal}
          setReloaded={setReloaded}
        />
      )}
    </div>
  );
};

export default DiscountsTable;
