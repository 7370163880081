import React from 'react';
import BlankModal from '@core/modals/BlankModal';
import Input from '@core/inputs/Input';
import RichTextEditor from '@core/richtext/RichTextEditor';
import moment from 'moment';
import { API_URL } from '@constants/index';

const ShowModal = ({ setOpenModal, show }) => {
  return (
    <BlankModal title="View show" setOpenModal={setOpenModal} maxWidth="max-w-7xl">
      <div className="grid grid-cols-2 gap-x-10 items-center">
        <div className="space-y-5 my-5">
          <Input disabled value={show.name} title="Name" />
          <RichTextEditor disabled data={show.description} title="Description" />
          <Input disabled value={show.basePrice} title="Base price" />
          <Input
            disabled
            value={moment(new Date(show.date)).format('DD.MM.YYYY HH:mm')}
            title="Date"
          />
        </div>
        <img src={`${API_URL}/thumbnail/${show.thumbnail}`} />
      </div>
    </BlankModal>
  );
};

export default ShowModal;
