import React, { useEffect, useState } from 'react';
import DefaultButton from '@core/buttons/electrons/DefaultButton';
import ChangePriceModal from '@components/Seats/ChangePriceModal';
import ConfirmReserveSeatsModal from '@components/Seats/ConfirmReserveSeatsModal';
import Floor from '@components/Seats/Floor';
import { hideSpinner, showSpinner } from '@redux/spinner/Action';
import SeatsService from '@services/SeatsService';
import { useDispatch } from 'react-redux';
import _ from 'lodash';
import { showError } from '@utils/helpers';
import ConfirmReleaseSeatsModal from '@components/Seats/ConfirmReleaseSeatsModal';
import { useHistory } from 'react-router-dom';
import greenSeat from '@assets/svg/greenSeat.svg';
import graySeat from '@assets/svg/graySeat.svg';
import redSeat from '@assets/svg/redSeat.svg';
import blueSeat from '@assets/svg/blueSeat.svg';

const Seatmap = ({ match }) => {
  const [floorIndex, setFloorIndex] = useState(0);
  const [selectedSeats, setSelectedSeats] = useState([]);
  const dispatch = useDispatch();
  const [viewChangePriceModal, setViewChangePriceModal] = useState(false);
  const [viewConfirmReserveModal, setViewConfirmReserveModal] = useState(false);
  const [viewConfirmReleaseModal, setViewConfirmReleaseModal] = useState(false);
  const [floors, setFloors] = useState([]);
  const [reloaded, setReloaded] = useState(0);
  const showId = _.get(match, 'params.id', '');
  const history = useHistory();

  useEffect(() => {
    dispatch(showSpinner());
    SeatsService.all(showId)
      .then((res) => {
        const data = _.get(res, 'data.data');
        setFloors(data);
      })
      .catch((error) => {
        showError(error.response.data.message);
        history.goBack();
      })
      .finally(() => {
        dispatch(hideSpinner());
      });
  }, [reloaded]);

  const Floors = floors.map((floor, index) => (
    <Floor
      showId={showId}
      key={index}
      floor={floor}
      selectedSeats={selectedSeats}
      setSelectedSeats={setSelectedSeats}
    />
  ));

  const seatClassnames = 'xs:w-2.5 sm:w-3 md:w-3.5 lg:w-4 xl:w-5 2xl:w-6';

  return (
    <div>
      <div className="border bg-white rounded justify-between items-center mb-5 py-2">
        <div className="mx-3 my-5">
          <label className="text-lg font-bold text-gray-800">Seat Map</label>
          <div className="flex items-center space-x-1 mt-5">
            <DefaultButton xs label="Change Price" onClick={() => setViewChangePriceModal(true)} />
            {selectedSeats.filter((seat) => !seat.reserved).length > 0 && (
              <DefaultButton xs label="Reserve" onClick={() => setViewConfirmReserveModal(true)} />
            )}
            {selectedSeats.filter((seat) => seat.reserved).length > 0 && (
              <DefaultButton xs label="Release" onClick={() => setViewConfirmReleaseModal(true)} />
            )}
          </div>
          <select
            className="my-3 bg-gray-50 border border-gray-300 text-gray-900 text-xs md:text-sm rounded-lg block mx-auto py-1.5 px-20 lg:py-2"
            onChange={(e) => setFloorIndex(e.target.value)}
            value={floorIndex}>
            <option value={0}>Skena</option>
            <option value={1}>Lozha 1</option>
            <option value={2}>Lozha 2</option>
          </select>
          <div className="flex space-x-5 justify-center my-5">
            <div className="flex items-center space-x-0.5">
              <img src={graySeat} className={seatClassnames} />
              <p className="text-xs sm:text-sm md:text-md">Available</p>
            </div>
            <div className="flex items-center space-x-0.5">
              <img src={greenSeat} className={seatClassnames} />
              <p className="text-xs sm:text-sm md:text-md">Selected</p>
            </div>
            <div className="flex items-center space-x-0.5">
              <img src={blueSeat} className={seatClassnames} />
              <p className="text-xs sm:text-sm md:text-md">Reserved</p>
            </div>
            <div className="flex items-center space-x-0.5">
              <img src={redSeat} className={seatClassnames} />
              <p className="text-xs sm:text-sm md:text-md">Booked</p>
            </div>
          </div>
          {Floors[floorIndex]}
          {floorIndex === 0 && (
            <div className="w-11/12 md:w-5/6 lg:w-11/12 xl:w-4/6 mx-auto text-center text-xs sm:text-sm md:text-base lg:text-lg border-x border-t border-black border-b-0 pt-1 sm:pt-2 md:pt-3">
              Skena
            </div>
          )}
        </div>
        {viewChangePriceModal && (
          <ChangePriceModal
            showId={showId}
            reload={() => setReloaded((prevState) => prevState + 1)}
            setSelectedSeats={setSelectedSeats}
            selectedSeats={selectedSeats}
            setOpenModal={setViewChangePriceModal}
          />
        )}
        {viewConfirmReserveModal && (
          <ConfirmReserveSeatsModal
            setReloaded={setReloaded}
            showId={showId}
            setOpenModal={setViewConfirmReserveModal}
            setSelectedSeats={setSelectedSeats}
            selectedSeats={selectedSeats}
          />
        )}
        {viewConfirmReleaseModal && (
          <ConfirmReleaseSeatsModal
            setOpenModal={setViewConfirmReleaseModal}
            showId={showId}
            setReloaded={setReloaded}
            setSelectedSeats={setSelectedSeats}
            selectedSeats={selectedSeats}
          />
        )}
      </div>
    </div>
  );
};

export default Seatmap;
