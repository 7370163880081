const addDiscountValidator = {
  name: {
    presence: {
      allowEmpty: false,
      message: "Name can't be blank"
    },
    type: 'string'
  },
  percentage: {
    presence: {
      allowEmpty: false,
      message: "Percentage can't be blank"
    },
    numericality: true
  }
};

export default addDiscountValidator;
