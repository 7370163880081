import React, { useState } from 'react';
import BlankModal from '@core/modals/BlankModal';
import Input from '@core/inputs/Input';
import useFormErrors from '@hooks/useFormErrors';
import editShowValidator from '@utils/validators/editShowValidator';
import ShowService from '@services/ShowService';
import { showError, showSuccess } from '@utils/helpers';
import { useDispatch } from 'react-redux';
import { hideSpinner, showSpinner } from '@redux/spinner/Action';
import DefaultButton from '@core/buttons/electrons/DefaultButton';
import moment from 'moment';
import RichTextEditor from '@core/richtext/RichTextEditor';
import { API_URL } from '@constants/index';
import FileInput from '@core/inputs/FileInput';

const EditShowModal = ({ setShowToEdit, showToEdit, setReloaded, setOpenModal }) => {
  const [name, setName] = useState(showToEdit.name);
  const [description, setDescription] = useState(showToEdit.description);
  const [date, setDate] = useState(moment(new Date(showToEdit.date)).format('YYYY-MM-DDTHH:mm'));
  const [basePrice, setBasePrice] = useState(showToEdit.basePrice);
  const [thumbnail, setThumbnail] = useState(null);
  const { getError, clearErrors, validateErrors } = useFormErrors();
  const dispatch = useDispatch();

  const onSubmit = () => {
    const errors = validateErrors({ name, date, description, basePrice }, editShowValidator);
    if (errors) {
      return;
    }
    const body = new FormData();
    body.append('name', name);
    body.append('description', description);
    body.append('date', new Date(date));
    if (thumbnail) {
      body.append('thumbnail', thumbnail);
    }
    body.append('basePrice', basePrice);
    dispatch(showSpinner());
    ShowService.edit(showToEdit.id, body)
      .then((res) => {
        setOpenModal(false);
        setShowToEdit(null);
        showSuccess(res.data.message);
        setReloaded((prevState) => prevState + 1);
      })
      .catch((error) => {
        showError(error.response.data.message);
      })
      .finally(() => {
        dispatch(hideSpinner());
      });
  };

  const handleFileChange = ([file]) => {
    setThumbnail(file);
  };

  const EditButton = <DefaultButton label="Edit Show" onClick={onSubmit} />;

  return (
    <BlankModal
      title="Edit Show"
      setOpenModal={setOpenModal}
      otherButtons={[EditButton]}
      maxWidth="max-w-7xl">
      <div className="grid gap-y-10 lg:gap-y-0 lg:grid-cols-2 lg:gap-x-10 items-center">
        <div className="space-y-3">
          <Input
            value={name}
            onChange={setName}
            placeholder="Name"
            onFocus={clearErrors}
            error={getError('name')}
          />
          <RichTextEditor
            data={description}
            error={getError('description')}
            onChange={setDescription}
            onFocus={clearErrors}
          />
          <Input
            type="number"
            value={basePrice}
            onChange={setBasePrice}
            placeholder="Base Price"
            onFocus={clearErrors}
            error={getError('basePrice')}
          />
          <Input
            min={new Date().toISOString().slice(0, new Date().toISOString().lastIndexOf(':'))}
            type="datetime-local"
            onFocus={clearErrors}
            value={date}
            onChange={setDate}
            error={getError('date')}
          />
          <FileInput
            accept="image/png, image/jpeg"
            error={getError('thumbnail')}
            type="file"
            onChange={handleFileChange}
          />
        </div>
        <img
          src={
            thumbnail
              ? URL.createObjectURL(thumbnail)
              : `${API_URL}/thumbnail/${showToEdit.thumbnail}`
          }
          className="mx-auto w-auto h-full"
        />
      </div>
    </BlankModal>
  );
};

export default EditShowModal;
