import React from 'react';
import { logout } from '@redux/authentication/Action';
import { useDispatch } from 'react-redux';
import { Menu } from '@headlessui/react';
import { Link } from 'react-router-dom';
import { FaBars } from 'react-icons/fa';
import image from '@assets/images/tob-logo 2.png';
import { FaCircle } from 'react-icons/fa';

const Header = ({ setIsSidebarOpen }) => {
  const dispatch = useDispatch();

  const handleLogout = () => {
    dispatch(logout());
  };

  return (
    <nav className="bg-white">
      <div className="relative flex items-center justify-between h-16 px-6">
        <FaBars
          className="cursor-pointer text-2xl lg:hidden"
          onClick={() => setIsSidebarOpen((prevState) => !prevState)}
        />
        <img src={image} className="h-full py-2" />
        <div className="flex justify-end py-3">
          <Menu as="div" className="relative">
            <Menu.Button className="flex items-center space-x-1 border p-1.5 rounded-md">
              <div className="relative">
                <FaCircle className="text-blue-600 text-4xl" />
                <span className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 text-lg font-semibold text-white">
                  A
                </span>
              </div>
              <span>Admin</span>
            </Menu.Button>
            <Menu.Items className="absolute right-0 bg-white rounded-md shadow-lg border w-48">
              <Menu.Item>
                <div
                  className="hover:bg-gray-100 block px-4 py-2 text-sm text-gray-700 cursor-pointer"
                  onClick={handleLogout}>
                  Log Out
                </div>
              </Menu.Item>
              <Menu.Item>
                <Link
                  to="/change-password"
                  className="hover:bg-gray-100 no-underline block px-4 py-2 text-sm text-gray-700 cursor-pointer">
                  Change Password
                </Link>
              </Menu.Item>
            </Menu.Items>
          </Menu>
        </div>
      </div>
    </nav>
  );
};

export default Header;
