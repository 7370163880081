import React from 'react';
import BlankModal from '@core/modals/BlankModal';
import { useDispatch } from 'react-redux';
import SeatsService from '@services/SeatsService';
import { hideSpinner, showSpinner } from '@redux/spinner/Action';
import { showError, showSuccess } from '@utils/helpers';
import DefaultButton from '@core/buttons/electrons/DefaultButton';

const ConfirmReserveSeatsModal = ({
  showId,
  setReloaded,
  setSelectedSeats,
  selectedSeats,
  setOpenModal
}) => {
  const dispatch = useDispatch();
  const seatsToBeReserved = selectedSeats.filter((seat) => !seat.reserved);

  const reserveSeats = () => {
    dispatch(showSpinner());
    SeatsService.reserve(
      seatsToBeReserved.map((seat) => seat.id),
      showId
    )
      .then((res) => {
        showSuccess(res.data.message);
        setSelectedSeats([]);
        setReloaded((prevState) => prevState + 1);
        setOpenModal(false);
      })
      .catch((error) => {
        showError(error.response.data.message);
      })
      .finally(() => {
        dispatch(hideSpinner());
      });
  };

  const ReserveButton = <DefaultButton label="Reserve" onClick={reserveSeats} />;

  return (
    <BlankModal
      title="Confirm seats reservation"
      setOpenModal={setOpenModal}
      otherButtons={[ReserveButton]}>
      <div className="overflow-y-auto max-h-72 my-3 w-full space-y-3">
        {seatsToBeReserved.map((seat) => (
          <div key={seat.id} className="space-x-3 flex justify-between border-b first:border-t p-1">
            <span>{seat.row + seat.name}</span>
            <span>{seat.price + ' All'}</span>
          </div>
        ))}
      </div>
    </BlankModal>
  );
};

export default ConfirmReserveSeatsModal;
