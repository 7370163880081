import React, { useState } from 'react';
import BlankModal from '@core/modals/BlankModal';
import Input from '@core/inputs/Input';
import DefaultButton from '@core/buttons/electrons/DefaultButton';
import SeatsService from '@services/SeatsService';
import { hideSpinner, showSpinner } from '@redux/spinner/Action';
import { useDispatch } from 'react-redux';
import { showError, showSuccess } from '@utils/helpers';
import useFormErrors from '@hooks/useFormErrors';
import changePriceValidator from '@utils/validators/changePriceValidator';

const ChangePriceModal = ({ showId, reload, setSelectedSeats, selectedSeats, setOpenModal }) => {
  const [price, setPrice] = useState('');
  const dispatch = useDispatch();
  const { clearErrors, getError, validateErrors } = useFormErrors();

  const changePrice = () => {
    const errors = validateErrors({ price }, changePriceValidator);
    if (errors) {
      return;
    }
    dispatch(showSpinner());
    const seatId = selectedSeats.map((seat) => seat.id);
    SeatsService.changePrice(seatId, price, showId)
      .then((res) => {
        setSelectedSeats([]);
        showSuccess(res.data.message);
        setOpenModal(false);
        reload();
      })
      .catch((error) => {
        showError(error.response.data.message);
      })
      .finally(() => {
        dispatch(hideSpinner());
      });
  };

  const ChangePriceButton = <DefaultButton label="Change Price" onClick={changePrice} />;

  return (
    <BlankModal
      maxWidth="sm:max-w-2xl"
      setOpenModal={setOpenModal}
      title="Change Price"
      otherButtons={[ChangePriceButton]}>
      <div className="grid grid-cols-2 gap-x-3 items-center mt-3">
        <div className="overflow-y-auto max-h-72 w-full space-y-3">
          {selectedSeats.map((seat, index) => (
            <div key={index} className="space-x-3 flex justify-between border-b first:border-t p-1">
              <span>{seat.row + seat.name}</span>
              <span>{seat.price + ' All'}</span>
            </div>
          ))}
        </div>
        <Input
          error={getError('price')}
          type="number"
          value={price}
          onFocus={clearErrors}
          placeholder="Price"
          onChange={setPrice}
        />
      </div>
    </BlankModal>
  );
};

export default ChangePriceModal;
