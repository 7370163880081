import React, { useState } from "react";
import { FaChevronDown, FaChevronRight } from "react-icons/fa";
import { Collapse } from "react-collapse";
import { useHistory } from "react-router-dom";
import useActiveMenuItem from "../../hooks/useActiveMenuItem";

const SidebarItem = ({ icon, label, link, children, exact = false }) => {
  const history = useHistory();
  const isActive = useActiveMenuItem(link, children, exact);
  const [isOpen, setIsOpen] = useState(isActive);

  const handleClick = () => {
    if (link) {
      return history.push(link);
    }

    setIsOpen((prev) => !prev);
  };

  return (
    <li className={"mx-2"}>
      <span
        onClick={handleClick}
        className={`flex flex-row items-center h-10 transform hover:translate-x-1 transition-transform ease-in duration-200 text-gray-500 hover:text-gray-700 cursor-pointer rounded-md ${
          isActive ? "text-primary-700 bg-gray-100 shadow" : "text-gray-500"
        }`}
      >
        <span
          className={`inline-flex items-center justify-center h-12 w-12 text-lg ${
            isActive ? "text-gray-700" : "text-gray-400"
          }`}
        >
          {icon}
        </span>
        <span
          className={`text-sm ${
            isActive ? "font-semibold text-gray-700" : "text-gray-500"
          }`}
        >
          {label}
        </span>
        {children && (
          <span className="ml-auto mr-2 text-gray-400">
            {isOpen ? <FaChevronDown /> : <FaChevronRight />}
          </span>
        )}
      </span>
      <Collapse isOpened={isOpen}>
        <ul className="flex flex-col bg-brand-header-bg">{children}</ul>
      </Collapse>
    </li>
  );
};

export default SidebarItem;
