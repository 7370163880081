const changePriceValidator = {
  price: {
    presence: {
      allowEmpty: false,
      message: "Price can't be blank"
    },
    numericality: true
  }
};

export default changePriceValidator;
