import React from 'react';
import AuthLayout from '@hoc/layouts/AuthLayout';
import ChangePasswordForm from '@components/ChangePassword/ChangePasswordForm';

const ChangePasswordPage = () => {
  return (
    <AuthLayout>
      <ChangePasswordForm />
    </AuthLayout>
  );
};

export default ChangePasswordPage;
