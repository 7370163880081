import React, { useState } from 'react';
import BlankModal from '@core/modals/BlankModal';
import DefaultButton from '@core/buttons/electrons/DefaultButton';
import TicketService from '@services/TicketService';
import { showError, showSuccess } from '@utils/helpers';
import { useDispatch } from 'react-redux';
import { hideSpinner, showSpinner } from '@redux/spinner/Action';
import { useHistory } from 'react-router-dom';
import Input from '@core/inputs/Input';
import useFormErrors from '@hooks/useFormErrors';
import refundReasonValidator from '@utils/validators/refundReasonValidator';

const RefundTicketModal = ({ setOpenModal, linkedTickets, ticketId }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [reason, setReason] = useState('');

  const { validateErrors, getError, clearErrors } = useFormErrors();

  const refundTicket = () => {
    const errors = validateErrors({ reason }, refundReasonValidator);
    if (errors) {
      return;
    }
    dispatch(showSpinner());
    TicketService.refund(ticketId, reason)
      .then((res) => {
        showSuccess(res.data.message);
        setOpenModal(false);
        history.goBack();
      })
      .catch((error) => {
        showError(error.response.data.message);
      })
      .finally(() => {
        dispatch(hideSpinner());
      });
  };

  const RefundButton = <DefaultButton label="Refund" onClick={refundTicket} />;

  return (
    <BlankModal title="Refund Tickets" setOpenModal={setOpenModal} otherButtons={[RefundButton]}>
      <div>
        {linkedTickets.length > 0 && (
          <div className="my-3">
            <p>{`There ${linkedTickets.length > 1 ? 'are' : 'is'} also ${linkedTickets.length} ${
              linkedTickets.length > 1 ? 'tickets' : 'ticket'
            } purchased with this ticket`}</p>
            <div className="space-y-3 my-3 max-h-72">
              {linkedTickets.map((ticket, index) => (
                <div
                  key={index}
                  className="flex items-center p-1 justify-between border-b last:border-b-0">
                  <p>{ticket.seat.row + ticket.seat.name}</p>
                  <p>{ticket.price + ' ALL'}</p>
                  <p>
                    {ticket.discount
                      ? `${ticket.discount.name} ${ticket.discount.percentage}%`
                      : 'No discount'}
                  </p>
                </div>
              ))}
            </div>
          </div>
        )}
        <Input
          placeholder="Enter a reason"
          value={reason}
          error={getError('reason')}
          onChange={setReason}
          onFocus={clearErrors}
        />
        <p className="mt-3">Are you sure you want to refund the tickets?</p>
      </div>
    </BlankModal>
  );
};

export default RefundTicketModal;
