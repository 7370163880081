import React, { useState } from 'react';
import BlankModal from '@core/modals/BlankModal';
import Input from '@core/inputs/Input';
import ShowService from '@services/ShowService';
import useFormErrors from '@hooks/useFormErrors';
import { showError, showSuccess } from '@utils/helpers';
import { useDispatch } from 'react-redux';
import { hideSpinner, showSpinner } from '@redux/spinner/Action';
import addShowValidator from '@utils/validators/addShowValidator';
import DefaultButton from '@core/buttons/electrons/DefaultButton';
import RichTextEditor from '@core/richtext/RichTextEditor';
import FileInput from '@core/inputs/FileInput';

const AddShowModal = ({ setOpenModal, setReloaded }) => {
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [date, setDate] = useState('');
  const [basePrice, setBasePrice] = useState('');
  const dispatch = useDispatch();
  const [thumbnail, setThumbnail] = useState(null);

  const { getError, clearErrors, validateErrors } = useFormErrors();

  const onSubmit = () => {
    const errors = validateErrors(
      { name, description, date, basePrice, thumbnail },
      addShowValidator
    );
    if (errors) {
      return;
    }
    const body = new FormData();
    body.append('name', name);
    body.append('description', description);
    body.append('date', new Date(date));
    body.append('thumbnail', thumbnail);
    body.append('basePrice', basePrice);
    dispatch(showSpinner());
    ShowService.create(body)
      .then((res) => {
        setOpenModal(false);
        showSuccess(res.data.message);
        setReloaded((prevState) => prevState + 1);
      })
      .catch((error) => {
        showError(error.response.data.message);
      })
      .finally(() => {
        dispatch(hideSpinner());
      });
  };

  const handleFileChange = ([file]) => {
    setThumbnail(file);
  };

  const AddButton = <DefaultButton label="Add Show" onClick={onSubmit} />;

  return (
    <BlankModal
      title="Add Show"
      setOpenModal={setOpenModal}
      otherButtons={[AddButton]}
      maxWidth="max-w-7xl">
      <div className="grid grid-cols-2 gap-x-10 items-center">
        <div className="space-y-3">
          <Input
            error={getError('name')}
            value={name}
            onChange={setName}
            placeholder="Name"
            onFocus={clearErrors}
          />
          <RichTextEditor
            data={description}
            error={getError('description')}
            onChange={setDescription}
            onFocus={clearErrors}
          />
          <Input
            error={getError('basePrice')}
            type="number"
            placeholder="Base price"
            value={basePrice}
            onChange={setBasePrice}
            onFocus={clearErrors}
          />
          <Input
            min={new Date().toISOString().slice(0, new Date().toISOString().lastIndexOf(':'))}
            type="datetime-local"
            onChange={setDate}
            onFocus={clearErrors}
            value={date}
            error={getError('date')}
          />
          <FileInput
            accept="image/png, image/jpeg"
            error={getError('thumbnail')}
            type="file"
            onChange={handleFileChange}
          />
        </div>
        {thumbnail && <img src={URL.createObjectURL(thumbnail)} className="h-full mx-auto" />}
      </div>
    </BlankModal>
  );
};

export default AddShowModal;
