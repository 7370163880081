import React from 'react';
import Layout from '@hoc/layouts/Layout';
import ClientsTable from '@components/Clients/ClientsTable';

const ClientsPage = () => {
  return (
    <Layout>
      <ClientsTable />
    </Layout>
  );
};

export default ClientsPage;
