const refundReasonValidator = {
  reason: {
    presence: {
      allowEmpty: false,
      message: "Refund reason can't be empty"
    }
  }
};

export default refundReasonValidator;
