import React from 'react';
import { FaTag, FaUserTag } from 'react-icons/fa';
import { GiTheaterCurtains } from 'react-icons/gi';
import SidebarItem from '@core/items/SidebarItem';
// import SidebarSubItem from "@core/items/SidebarSubItem";

const Sidebar = ({ isSidebarOpen }) => {
  return (
    <aside
      className={`absolute lg:static ${
        !isSidebarOpen && 'hidden lg:block'
      } h-screen z-10 duration-200 transition origin-left min-w-max w-56 bg-white border-x border-b`}>
      <ul className="flex flex-col py-4">
        <SidebarItem icon={<FaTag />} label={'Discounts'} link={'/discounts'} />
        <SidebarItem icon={<GiTheaterCurtains />} label={'Show'} link={'/show'} />
        <SidebarItem icon={<FaUserTag />} label={'Clients'} link={'/clients'} />
        {/*<SidebarItem icon={<FaMusic />} label={'Music'}>*/}
        {/*  <SidebarSubItem label={'music1'} link={'/music1'} />*/}
        {/*  <SidebarSubItem label={'music2'} link={'/music2'} />*/}
        {/*  <SidebarSubItem label={'music3'} link={'/music3'} />*/}
        {/*</SidebarItem>*/}
      </ul>
    </aside>
  );
};

export default Sidebar;
