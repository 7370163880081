import React from 'react';
import { FaTrash } from 'react-icons/fa';
import DefaultButton from '@core/buttons/electrons/DefaultButton';

const DeleteButton = ({ onClick, label = 'Edit', ...props }) => {
  return (
    <DefaultButton
      label={
        <div className={'flex items-center'}>
          <FaTrash />
        </div>
      }
      bgColor={'bg-red-100'}
      bgColorHover={'hover:bg-red-300'}
      textColor={'text-red-700'}
      onClick={onClick}
    />
  );
};

export default DeleteButton;
