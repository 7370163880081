import {useHistory} from "react-router-dom";

const useActiveMenuItem = (link, children, exact = false) => {
  const history = useHistory()
  const currentLocation = history.location.pathname;
  let isActive = exact ? currentLocation === link : currentLocation.startsWith(link);
  if (children) {
    children.forEach((child) => {
      const childLink = child.props.link;
      if (currentLocation.startsWith(childLink)) {
        return isActive = true
      }
    })
  }

  return isActive;
};

export default useActiveMenuItem;
