import API from '@utils/plugins/API';

const AuthService = {
  login: (email, password) => {
    return API.post('/auth/login', { email, password });
  },
  changePassword: (newPassword, oldPassword, userId) =>
    API.post(`/auth/change-password/${userId}`, { newPassword, oldPassword })
};

export default AuthService;
