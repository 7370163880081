import React from 'react';
import Layout from '@hoc/layouts/Layout';
import ShowTable from '@components/Show/ShowTable';

const ShowPage = () => {
  return (
    <Layout>
      <ShowTable />
    </Layout>
  );
};

export default ShowPage;
