import React from 'react';
import SearchInput from '@core/inputs/SearchInput';
import Pagination from '@core/table/Pagination';
import EmptyTable from '@core/table/EmptyTable';
import Lottie from '@core/Lottie';

const MobileTable = ({
  title,
  headers,
  data = [],
  isLoading,
  page,
  onPageChange,
  totalRows,
  pageCount,
  search,
  noDataComponent,
  onRowClicked,
  classname = ''
}) => {
  return (
    <div className={classname}>
      <label className="text-lg font-bold text-gray-800">{title}</label>
      <div className="mt-2 p-1 flex rounded-md">
        <SearchInput placeholder="Search" onKeyUp={search} />
      </div>

      {isLoading ? (
        <Lottie animationData={require('@assets/animations/empty_results.json')} />
      ) : (
        <>
          {pageCount === 0
            ? noDataComponent || (
                <div className="flex mx-auto mt-4 w-10/12">
                  <EmptyTable />
                </div>
              )
            : data.map((row) => (
                <div
                  key={row.id}
                  onClick={() => (onRowClicked ? onRowClicked(row) : null)}
                  className="my-6 border-t border-gray-100 rounded-md shadow-ml bg-white">
                  {headers.map((header) => {
                    const { id, name, selector, cell } = header;
                    const data = selector ? selector(row) : cell(row);
                    return (
                      data && (
                        <div
                          key={id}
                          className={`flex ${
                            name ? 'justify-between' : 'justify-center'
                          } p-3 last:border-none border-b items-center`}>
                          {name && (
                            <div className="flex-1 font-semibold text-xs text-gray-700">{name}</div>
                          )}
                          <div
                            className={`flex flex-1 overflow-wrap ${
                              name ? 'justify-end' : 'justify-center'
                            } text-xs`}>
                            {data}
                          </div>
                        </div>
                      )
                    );
                  })}
                </div>
              ))}
          {pageCount > 1 && (
            <Pagination
              page={page}
              pageCount={pageCount}
              onPageChange={onPageChange}
              totalRows={totalRows}
              className="flex justify-center"
            />
          )}
        </>
      )}
    </div>
  );
};

export default MobileTable;
