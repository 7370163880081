import API from '@utils/plugins/API';

const SeatsService = {
  all: (showId) => API.get(`/internal/seat/${showId}`),
  changePrice: (seatId, price, showId) =>
    API.post('/internal/seat-price', { seatId, price, showId }),
  reserve: (seatId, showId) => API.post('/internal/reservation/reserve', { showId, seatId }),
  release: (seatId, showId) => API.post('/internal/reservation/release', { showId, seatId }),
  delete: (seatId) => API.delete(`/${seatId}`)
};

export default SeatsService;
