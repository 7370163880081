import DataTable from 'react-data-table-component';
import React, { useEffect } from 'react';
import { useState } from 'react';
import SearchInput from '@core/inputs/SearchInput';
import _ from 'lodash';
import EmptyTable from '@core/table/EmptyTable';
import LoadingTable from '@core/table/LoadingTable';
import MobileTable from '@core/table/MobileTable';
import { isMobile } from 'react-device-detect';

const Datatable = ({
  headers,
  totalRows,
  getData = () => {},
  initialPageSize = 10,
  title = null,
  data = null,
  onRowClicked = () => {},
  isLoading = false,
  pointerOnHover = false,
  extraDependencies = [],
  ...props
}) => {
  const [sortColumn, setSortColumn] = useState('createdAt');
  const [sortDirection, setSortDirection] = useState('desc');
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(initialPageSize);
  const [qs, setQs] = useState(null);

  const handleSort = async (column, sortDirection) => {
    setSortColumn(column.id);
    setSortDirection(sortDirection);
  };

  const handlePageChange = (page) => {
    setPage(page);
  };

  const handlePageSizeChange = (pageSize) => {
    setPageSize(pageSize);
  };

  const search = _.debounce((e) => {
    setQs(e ? e : null);
  }, 400);

  useEffect(() => {
    const params = {};
    if (pageSize) {
      params.pageSize = pageSize;
    }
    if (page) {
      params.page = page;
    }
    if (qs) {
      params.qs = qs;
    }
    // if (sortColumn && sortDirection) {
    //   params.sortColumn = sortColumn;
    //   params.sortDirection = sortDirection;
    // }
    getData(params);
    // eslint-disable-next-line
  }, [page, pageSize, qs, sortDirection, sortColumn, ...extraDependencies]);

  return (
    <>
      <div className={isMobile ? 'hidden' : 'block'}>
        <div
          className={`flex ${
            title ? 'justify-between' : 'justify-end'
          } my-2 pt-3 pb-1 items-center`}>
          {title && <label className="text-lg font-bold text-gray-800">{title}</label>}
          <SearchInput onKeyUp={search} placeholder="Search..." />
        </div>
        <hr />
        <DataTable
          columns={headers}
          data={data}
          pagination
          striped
          paginationServer
          paginationTotalRows={totalRows}
          paginationPerPage={pageSize}
          paginationDefaultPage={page}
          onChangePage={(page) => handlePageChange(page)}
          onChangeRowsPerPage={handlePageSizeChange}
          onSort={handleSort}
          sortServer
          onRowClicked={onRowClicked}
          progressPending={isLoading}
          progressComponent={<LoadingTable />}
          pointerOnHover={pointerOnHover}
          persistTableHead={true}
          noDataComponent={<EmptyTable />}
          {...props}
        />
      </div>
      <div className={isMobile ? 'block' : 'hidden'}>
        <MobileTable
          headers={headers}
          pagination
          striped
          title={title}
          data={data}
          paginationServer
          paginationTotalRows={totalRows}
          paginationPerPage={pageSize}
          paginationDefaultPage={page}
          onChangePage={(page) => handlePageChange(page)}
          onChangeRowsPerPage={handlePageSizeChange}
          onSort={handleSort}
          sortServer
          search={search}
          onRowClicked={onRowClicked}
          progressPending={isLoading}
          progressComponent={<LoadingTable />}
          pointerOnHover={pointerOnHover}
          persistTableHead={true}
          noDataComponent={<EmptyTable />}
          {...props}
        />
      </div>
    </>
  );
};

export default Datatable;
