import React, { useState } from 'react';
import Sidebar from '@hoc/partials/Sidebar';
import Header from '@hoc/partials/Header';

const Layout = ({ children }) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  return (
    <div>
      <Header setIsSidebarOpen={setIsSidebarOpen} />
      <div className={'min-h-screen flex flex-row bg-gray-100'}>
        <Sidebar isSidebarOpen={isSidebarOpen} />
        <div className={'flex-1'}>
          <div className="my-5 mx-2">{children}</div>
        </div>
      </div>
    </div>
  );
};

export default Layout;
