import React from 'react';
import AuthLayout from '@hoc/layouts/AuthLayout';
import Scanner from '@components/TicketScanner/Scanner';
const TicketScannerPage = (props) => {
  return (
    <AuthLayout>
      <Scanner {...props} />
    </AuthLayout>
  );
};
export default TicketScannerPage;
