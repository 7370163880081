import React from 'react';
import {Route, Redirect} from 'react-router-dom';
import {useSelector} from 'react-redux';
import _ from 'lodash';

export const GuestRoute = ({component: Component, ...rest}) => {
  const accessToken = useSelector((state) =>
    _.get(state, "authenticationReducer.accessToken", null)
  );

  return (
    <Route
      {...rest}
      render={(props) => {
        if (accessToken) {
          return (
            <Redirect
              to={{
                pathname: '/',
                state: {from: props.location},
              }}
            />
          );
        }
        return <Component {...props} />;
      }}
    />
  );
};

export default GuestRoute;
