import React from 'react';
import BlankModal from '@core/modals/BlankModal';
import { hideSpinner, showSpinner } from '@redux/spinner/Action';
import SeatsService from '@services/SeatsService';
import { showError, showSuccess } from '@utils/helpers';
import DefaultButton from '@core/buttons/electrons/DefaultButton';
import { useDispatch } from 'react-redux';

const ConfirmReleaseSeatsModal = ({
  setOpenModal,
  selectedSeats,
  setSelectedSeats,
  showId,
  setReloaded
}) => {
  const dispatch = useDispatch();
  const seatsToBeReleased = selectedSeats.filter((seat) => seat.reserved);
  const release = () => {
    dispatch(showSpinner());
    SeatsService.release(
      seatsToBeReleased.map((seat) => seat.id),
      showId
    )
      .then((res) => {
        showSuccess(res.data.message);
        setSelectedSeats([]);
        setReloaded((prevState) => prevState + 1);
        setOpenModal(false);
      })
      .catch((error) => {
        showError(error.response.data.message);
      })
      .finally(() => {
        dispatch(hideSpinner());
      });
  };

  const ReleaseButton = <DefaultButton label="Release" onClick={release} />;

  return (
    <BlankModal
      title="Confirm seats release"
      setOpenModal={setOpenModal}
      otherButtons={[ReleaseButton]}>
      <div className="overflow-y-auto max-h-72 my-3 w-full space-y-3">
        {seatsToBeReleased.map((seat) => (
          <div key={seat.id} className="space-x-3 flex justify-between border-b first:border-t p-1">
            <span>{seat.row + seat.name}</span>
            <span>{seat.price + ' All'}</span>
          </div>
        ))}
      </div>
    </BlankModal>
  );
};

export default ConfirmReleaseSeatsModal;
