import moment from 'moment';
import validate from 'validate.js';

validate.extend(validate.validators.datetime, {
  parse: (value) => +moment.utc(value),
  format: (value, options) => {
    let format = options.dateOnly ? 'YYYY-MM-DD' : 'YYYY-MM-DD hh:mm:ss';
    return moment.utc(value).format(format);
  }
});

const editShowValidator = {
  name: {
    type: 'string',
    presence: {
      allowEmpty: false,
      message: "Name can't be blank"
    }
  },
  description: {
    type: 'string',
    presence: {
      allowEmpty: false,
      message: "Description can't be blank"
    }
  },
  date: {
    type: 'string',
    presence: {
      allowEmpty: false,
      message: "Date can't be blank"
    }
  },
  basePrice: {
    presence: {
      allowEmpty: false,
      message: "Base price can't be blank"
    }
  }
};

export default editShowValidator;
