import React from 'react';
import Layout from '@hoc/layouts/Layout';
import TicketInfo from '@components/Ticket/TicketInfo';

const TicketPage = (props) => {
  return (
    <Layout>
      <TicketInfo {...props} />
    </Layout>
  );
};

export default TicketPage;
