import React from 'react';
import { Switch, BrowserRouter as Router, Redirect } from 'react-router-dom';
import LoginPage from '@pages/LoginPage';
import AuthRoute from '@router/AuthRoute';
import GuestRoute from '@router/GuestRoute';
import DiscountsPage from '@pages/DiscountsPage';
import ShowPage from '@pages/ShowPage';
import SeatsPage from '@pages/SeatsPage';
import ClientsPage from '@pages/ClientsPage';
import TicketPage from '@pages/TicketPage';
import ChangePasswordPage from '@pages/ChangePasswordPage';
import TicketScannerPage from '@pages/TicketScannerPage';

const Routes = () => {
  return (
    <Router>
      <Switch>
        <GuestRoute exact path="/login" component={LoginPage} />
        <AuthRoute exact path="/discounts" component={DiscountsPage} />
        <AuthRoute exact path="/show" component={ShowPage} />
        <AuthRoute exact path="/seats/:id" component={SeatsPage} />
        <AuthRoute exact path="/clients" component={ClientsPage} />
        <AuthRoute exact path="/ticket/:id" component={TicketPage} />
        <AuthRoute exact path="/change-password" component={ChangePasswordPage} />
        <AuthRoute exact path="/ticket-scan/:showId" component={TicketScannerPage} />
        <Redirect from="*" to="/show" />
      </Switch>
    </Router>
  );
};

export default Routes;
