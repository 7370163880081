import React from "react";
import Lottie from "@core/Lottie";

const EmptyTable = () => {
  return (
    <div className={"w-full"}>
      <div className={"w-1/3 mx-auto my-5"}>
        <Lottie
          animationData={require('@assets/animations/empty_results.json')}
        />

        <div
          className={"text-md font-normal text-center text-gray-700 mt-5 mb-10"}
        >
          No data.
        </div>
      </div>
    </div>
  );
};
export default EmptyTable;
