import React, { useState } from 'react';
import Tippy from '@tippyjs/react/headless';
import 'tippy.js/dist/tippy.css';
import DefaultButton from '@core/buttons/electrons/DefaultButton';
import redSeat from '@assets/svg/redSeat.svg';
import graySeat from '@assets/svg/graySeat.svg';
import blueSeat from '@assets/svg/blueSeat.svg';
import greenSeat from '@assets/svg/greenSeat.svg';

const Seat = ({ seats, x, y, selectedSeats, setSelectedSeats }) => {
  const seat = seats.find((seat) => seat.x === x && seat.y === y);
  const isSeatSelected =
    seat && selectedSeats.some((selectedSeat) => seat.id === selectedSeat.id && !seat.booked);
  const [isSeatClicked, setIsSeatClicked] = useState(false);

  const handleSeatClick = (e) => {
    if (!seat.booked && e.ctrlKey) {
      if (selectedSeats.some((selectedSeat) => seat.id === selectedSeat.id)) {
        setSelectedSeats((prevSeats) => prevSeats.filter((prevSeat) => prevSeat.id !== seat.id));
      } else {
        setSelectedSeats((prevSeats) => [...prevSeats, seat]);
      }
      return;
    }
    setIsSeatClicked(true);
  };

  const selectSeat = () => {
    setSelectedSeats((prevSeats) => [...prevSeats, seat]);
    setIsSeatClicked(false);
  };

  const unSelectSeats = () => {
    setSelectedSeats((prevSeats) => prevSeats.filter((prevSeat) => seat.id !== prevSeat.id));
    setIsSeatClicked(false);
  };

  const seatWidthClassnames = 'w-2.5 sm:w-4 md:w-4 lg:w-5.5 xl:w-6 2xl:w-7';

  if (seat)
    return (
      <Tippy
        interactive
        visible={isSeatClicked}
        onClickOutside={() => setIsSeatClicked(false)}
        render={() => (
          <div className="px-2.5 py-1.5 md:px-5 md:py-3 bg-gray-50 rounded-lg border bg-white">
            <p className="text-center text-base md:text-lg font-semibold mb-1">
              {seat.row + seat.name}
            </p>
            <p className="text-center text-sm md:text-base mb-2">{seat.price + ' ALL'}</p>
            {seat.booked ? (
              <DefaultButton
                xs
                fullWidth
                link
                to={`/ticket/${seat.ticketId}`}
                label="View ticket"
              />
            ) : (
              <>
                {seat.reserved && (
                  <h1 className="text-center text-lg font-semibold mb-1">Reserved</h1>
                )}
                {isSeatSelected ? (
                  <DefaultButton xs label="Unselect" fullWidth onClick={unSelectSeats} />
                ) : (
                  <DefaultButton xs label="Select" fullWidth onClick={selectSeat} />
                )}
              </>
            )}
          </div>
        )}>
        <div
          className={`relative flex items-center flex-col ${
            seat.booked ? 'cursor-default' : 'cursor-pointer'
          } ${seatWidthClassnames} h-auto ${
            isSeatClicked && 'border border-black md:border-2'
          } rounded`}
          onClick={handleSeatClick}>
          <img
            src={
              seat.booked
                ? redSeat
                : isSeatSelected
                ? greenSeat
                : seat.reserved
                ? blueSeat
                : graySeat
            }
            className={`w-full ${isSeatClicked && 'drop-shadow-2xl'}`}
          />
          <span className="text-[4px] sm:text-[6px] md:text-[8px] lg:text-[10px] xl:text-xs absolute top-0">
            {seat.name}
          </span>
        </div>
      </Tippy>
    );
  return <div className={seatWidthClassnames} />;
};

export default Seat;
