import React from 'react';
import { Link } from 'react-router-dom';

const DefaultButton = ({
  label,
  link,
  to,
  onClick,
  bgColor,
  bgColorHover,
  textColor,
  xs = false,
  sm = false,
  fullWidth = false,
  ...props
}) => {
  let sizingClasses = 'py-2 px-4 text-sm';
  if (xs) {
    sizingClasses = 'py-1 px-3 text-xs';
  }
  if (sm) {
    sizingClasses = 'py-2 px-3 text-xs';
  }

  const className = `${
    fullWidth ? 'w-full' : 'w-auto'
  } m-1 group relative flex justify-center border border-transparent font-medium rounded-md ${
    textColor ? textColor : 'text-white'
  } ${sizingClasses} ${bgColor ? bgColor : 'bg-blue-500'}  ${
    bgColorHover ? bgColorHover : 'hover:bg-blue-600'
  } focus:outline-none no-underline`;

  if (link) {
    return (
      <Link to={to} className={className} {...props}>
        {label}
      </Link>
    );
  }

  return (
    <button onClick={onClick} className={className} {...props}>
      {label}
    </button>
  );
};

export default DefaultButton;
