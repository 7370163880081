const confirmDeleteValidator = {
  confirmDeleteText: {
    presence: {
      allowEmpty: false,
      message: "Confirm text can't be blank"
    },
    equality: {
      attribute: 'deleteText',
      message: "Confirm text doesn't match",
      comparator: (v1, v2) => v1 === v2
    }
  }
};

export default confirmDeleteValidator;
